import React from 'react'
import styled from 'styled-components'

const Heading = styled.h2`
  color: var(--color-secondary);
  font-weight: var(--font-weight-medium);
  text-transform: uppercase;
  padding: 16px 0 32px 0;
  letter-spacing: 2px;
`

const HeadingSectionTitle: React.FC<HeadingSectionTitleProps> = ({
  children,
  as,
}) => <Heading as={as}>{children}</Heading>

export interface HeadingSectionTitleProps {
  as: React.ElementType
}

export default HeadingSectionTitle
